import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Footer from "../components/Footer";
import Header from "../components/Header";
import PrivacyPolicy from "../components/Privacy";

interface PrivacyProps {
  data: {
    mdx: {
      slug: string;
      rawBody: string;
    };
  };
}

const Privacy = ({ data }: PrivacyProps) => {
  return (
    <>
      <Helmet>
        <title>
          {
            // eslint-disable-next-line i18next/no-literal-string
          }
          LogRock - The only FREE compliance software
        </title>
      </Helmet>
      <Header />
      <PrivacyPolicy document={data.mdx} />
      <Footer />
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          data
          ns
          language
        }
      }
    }
    mdx(slug: { eq: "Logrock-Privacy-v2" }) {
      slug
      rawBody
    }
  }
`;

export default Privacy;
